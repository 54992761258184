.texto_pregunta_popup{
    font-size:28px;
    text-align: center;
}

.texto_respuesta_popup{
    font-size: 12px;
    width:80%;
    display:inline-flex;
}
[type=radio] { 
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* IMAGE STYLES */
  [type=radio] + img {
    cursor: pointer;
  }
  
  /* CHECKED STYLES */
  [type=radio]:checked + img {
    outline: 2px solid #f00;
  }
  .ant-radio{
    display:none!important;
  }
  .ant-radio-group{
    display:inline-flex!important;
  }
  .img_votar{
      width: 80%;
  }
  .enviar_voto{
      cursor: pointer;
      width: 100%;
  }
  .sistema_votacion{
    color:#033066; 
    font-size: 32px;
    text-align:center;
    margin:0px;
    line-height: 1;
  }
  .qr_texto{
    font-size: 26px;
    color:#000000;
    text-align: left;
    margin-bottom: 0px;
    margin-left: 10px;
  }
  .respuesta_enviada{
    font-size: 22px;
    color: green;
    font-weight: bold;
  }
  .activar_envivo{
    background-color: #033066;
    font-weight: bold;
    color: #fff;
    padding: 5px 0px;
    font-size: 18px;
    cursor: pointer;
  }
  .desactivar_envivo{
    background-color: #888787;
    font-weight: bold;
    color: #fff;
    padding: 5px 0px;
    font-size: 18px;
    cursor: pointer;
  }
  @media only screen and (max-width: 600px) {
    .sistema_votacion{
      font-size: 18px;
    }
    .qr_texto{
    font-size: 14px;
    }
  }