.texto_pregunta_cuestionario{
    text-align: left;
    color: black;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0px;
}

.texto_respuesta_cuestionario{
    text-align: left;
    color: black !important;
    display: block;
    -webkit-hyphens: manual;
    -ms-hyphens: manual;
    hyphens: manual;
}

.radio_pregunta_cuestionario{
    text-align: left;
    hyphens: manual !important;
}
.p_pregunta_cuestionario{
    hyphens: manual !important;
}
.contenedor_parrafo{
    
    overflow:scroll;
}

.btn_enviar_cuestionario{
    background-color: #0d3159;
    color: #ffffff;
    border-radius: 2px;
    cursor: pointer;
    border: none;
    font-size: 20px;
    padding: 3px 10px;
}
@media only screen and (max-width: 990px) {
    .texto_pregunta_cuestionario{
        
        font-size: 14px;
    }
  }

  .boton_descargar_diploma{
      background-color: cornflowerblue;
      color: white;
      text-decoration: none;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 18px;
  }