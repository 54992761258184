.table_crucigrama{
  background-image: url(../images/crucigrama/antp_FondoCrucigrama.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 3%;
  width: 100%;
  
}
.input_letra{
    width: 20px;
    height: 20px;
    background: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
}
.span_vacio{
  background-color: black;
  width: 30px;
  height: 20px;
}

.inline_obj{
  display: inline-flex;
}

.td_inicial{
  background-color:white;
  color:black;
  border: 1px solid black;
}

.td_inicial input{
  background-color:rgba(0,0,0,0);
  border: 1px solid white;
  text-transform: uppercase;
  text-align: center;
}

.td_verificado{
  background-color:greenyellow;
  color:black;
  border: 1px solid black;
}

.td_verificado input{
  background-color:rgba(0,0,0,0);
  border: 1px solid greenyellow;
  text-transform: uppercase;
  text-align: center;
}

.td_espacio{
  background-color: #000000;
}

.td_falso{
  background-color: rgba(0,0,0,0.1);
}
.tipo_crucigrama{
  color:rgb(72,149,210);
  font-size: 24px;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}
.pistas_crucigrama{
  font-size: 12px;
  text-align: justify;
  color:#0d3159;
}

.sup_indice_crusigrama{
  margin-top: 9px;
  display: block;
}