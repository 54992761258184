.footer{
  background-color: #ececec;
}
.text-blanco{
  color: #ffffff;
  text-align: left;
}
.text-azul{
  color: #0d3159;
  text-align: center;
}
.tituLogos{
  border-bottom: 4px solid #000000;
  font-size: 2em;
  text-align: left;
  color: #000000;
  padding-top: 50px;
}
.redes{
  color: #eb4b1c;
  width: 40px;
}
.link-footer{
  color: #000000;
  text-decoration: none;
  text-align: left;
}
.titu-footer{
  color: #eb4b1c;
  font-size: 2.5em;
  text-align: left;
  font-family: 'Economica', sans-serif;
  margin-bottom: 10px;;
}