.estilo_campos{
    color:white;
}

.contenedor_registro{
    
}

.contenedor_img_sin_login{
    text-align: center;
}

.img_iniciar_sin_login{
    width: 180px;
    cursor: pointer;
}
.iniciar_login{
    text-align: center;
    color: #112f6d; 
    font-size: 34px;
}
@media only screen and (max-width: 790px) {
    .iniciar_login{
        font-size: 24px;
    }
}