.container{
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  display: inline-flex;
  width: 100%;
}

.questionContainer{
  margin: 15px 5px;
  padding:15px;
  background-color: #ffffff;
  border:1px solid rgba(255,255,255,0.5);
}

.questionAnswer{
  margin: 0px 5px;
  padding:0px;
  background-color: #ffffff;
}

.acceptButton{
  color:rgb(65,176,44);
  background-color: white;
  margin: 5px;
  border: none;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.acceptButton:hover{
  color:white;
  background-color: rgb(65,176,44);
  border-radius: 50%;
  font-weight: bold;
  font-size: 20px;
}

.answerButton{
  background-color:white;
  color: rgb(83,167,225);
  margin: 5px;
  border: none;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.answerButton:hover{
  background-color:rgb(83,167,225);
  color: white;
  border-radius: 50%;
  font-weight: bold;
  font-size: 20px;
}

.retrieveButton{
  background-color:white;
  color: rgb(240, 213, 47);
  margin: 5px;
  border: none;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.retrieveButton:hover{
  background-color:rgb(240, 213, 47);
  color: white;
  border-radius: 50%;
  font-weight: bold;
  font-size: 20px;
}

.rejectButton{
  background-color: white;
  color: rgb(218, 0, 35);
  margin: 5px;
  border: none;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.rejectButton:hover{
  background-color: rgb(218, 0, 35);
  color: white;
  border-radius: 50%;
  font-weight: bold;
  font-size: 20px;
}

.editButton{
  background-color: white;
  color: orange;
  margin: 5px;
  border: none;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.editButton:hover{
  background-color: orange;
  color:white;
  border-radius: 50%;
  font-weight: bold;
  font-size: 20px;
}

.admin_preguntas_contendor{
  background-color: rgb(237,237,237);
}

.responderButton{
  background-color: white;
  color: orange;
    border: none;
    font-weight: bold;
    width: 100%;
    cursor: pointer;
}

.responderButton:hover{
  background-color: orange;
  color:white;
}

.titulo_respuestas{
  text-align:center;
  font-weight:bold;
  background-color: purple;
  color:white;
}



.botones{
  text-align: right;
  margin:0px;
}
.question{
  font-size: 15px;
  text-align: left;
  margin:0px;
}
.nombre_pregunta{
  font-style: italic;
  font-weight: bold;
}
.admin_titulo{
color: #000000;
font-size: 44px;
font-weight: bold;
margin-bottom: 0px;
padding-bottom: 0px;
}
.admin_encabezado{
  background-color: #000000;
  border-right: 2px solid #ffffff;
}
.admin_texto_encabezado{
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0px;
}